import React, { useContext } from "react"
import { LoadingContext } from "./../context/LoadingContext"
import equis from "../../images/popUpCumple/equis.png"
import cumple from "../../images/popUpCumple/Cumple30Exsis2x.png"
import cumpleEn from "../../images/popUpCumple/Cumple30Exsis2xEN.jpg"
import follow from "../../images/popUpCumple/followUs.png"
import i18n from "i18next"

const PopUp = props => {

  const { windowWidth } = useContext(LoadingContext)
  if (windowWidth > 2000) {
    return (
      <div className="popup-box-cumple">
        <div className="box-cumple">
          <div className="contenedor-columnas">
            <div className="columna-cumple">
              <img
                src={i18n.language === "es" ? cumple : cumpleEn}
                alt="Birthday image"
                className="birthday-image"
              />
            </div>
            <div className="columna-cumple">
              <img
                onClick={props.handleClose}
                className="equis-cumple"
                src={equis}
                alt="cierre"
              />
              
              <div>
                <h2 className="tittleHBDExsis">{i18n.t("home.hbdExsis.title")}</h2>
                <p className="txtHBDExsis">{i18n.t("home.hbdExsis.text")}</p>
                <p className="hashtagHBDExsis">{i18n.t("home.hbdExsis.hashtag")}</p>
              </div>
              <a
                href="https://www.linkedin.com/posts/exsis-digital_30-a%C3%B1os-activity-7247356811656196096-NFH8?utm_source=share&utm_medium=member_desktop"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={follow}
                  alt="Birthday follow us"
                  className="birthday-follow"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }else{
  if (windowWidth > 1600) {
    return (
      <div className="popup-box-cumple">
        <div className="box-cumple">
          <div className="contenedor-columnas">
            <div className="columna-cumple">
              <img
                src={i18n.language === "es" ? cumple : cumpleEn}
                alt="Birthday image"
                className="birthday-image"
              />
            </div>
            <div className="columna-cumple">
              <img
                onClick={props.handleClose}
                className="equis-cumple"
                src={equis}
                alt="cierre"
              />
              
              <div>
                <h2 className="tittleHBDExsis">{i18n.t("home.hbdExsis.title")}</h2>
                <p className="txtHBDExsis">{i18n.t("home.hbdExsis.text")}</p>
                <p className="hashtagHBDExsis">{i18n.t("home.hbdExsis.hashtag")}</p>
              </div>
              <a
                href="https://www.linkedin.com/posts/exsis-digital_30-a%C3%B1os-activity-7247356811656196096-NFH8?utm_source=share&utm_medium=member_desktop"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={follow}
                  alt="Birthday follow us"
                  className="birthday-follow"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    if (windowWidth > 1100) {
    return (
      <div className="popup-box-cumple">
        <div className="box-cumple">
          <div className="contenedor-columnas">
            <div className="columna-cumple">
              <img
                src={i18n.language === "es" ? cumple : cumpleEn}
                alt="Birthday image"
                className="birthday-image"
              />
            </div>
            <div className="columna-cumple">
              <img
                onClick={props.handleClose}
                className="equis-cumple"
                src={equis}
                alt="cierre"
              />
              
              <div>
                <h2 className="tittleHBDExsisMobile2">{i18n.t("home.hbdExsis.title")}</h2>
                <p className="txtHBDExsisMobile">{i18n.t("home.hbdExsis.text")}</p>
                <p className="hashtagHBDExsisMobile">{i18n.t("home.hbdExsis.hashtag")}</p>
              </div>
              <a
                href="https://www.linkedin.com/posts/exsis-digital_30-a%C3%B1os-activity-7247356811656196096-NFH8?utm_source=share&utm_medium=member_desktop"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={follow}
                  alt="Birthday follow us"
                  className="birthday-follow"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
  else{
    return (
      <div className="popup-box-cumple">
        <div className="box-cumpleMb">
            <div className="columna-cumple">
              <img
                src={i18n.language === "es" ? cumple : cumpleEn}
                alt="Birthday image"
                className="birthday-image"
              />
              <div className="btnClosePopup">
                <button 
                  onClick={props.handleClose}
                  className="bg-blue-100 w-full desktop:w-fit text-white text-16 font-sofiaPro-bold rounded-lg px-12 py-4">
                    {i18n.t("home.hbdExsis.close")}
                </button>
              </div>
            </div>
        </div>
      </div>
    )
  }
}}
}

export default PopUp
